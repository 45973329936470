

export default function VisAVis() {

    return (
        <article  className="writing">
            <header className="writing">
                <h1>Vis à Vis</h1>
                <p className="subtitle">Published in the Fall 2016 Issue of <a target="_blank" href="https://inscape.byu.edu/2017/01/27/vis-a-vis/">Inscape literary magazine</a></p>
            </header>
            <p>It’s July 31, 2016. The time is 11:58 am. After six long days, I’ve come back to my lakeside apartment in Slidell, Louisiana for a few hours of solitude. I retrieve a pad of graphing paper and a pen for my thoughts. It’s a habit I keep every few days and that I’ve kept for as long as I can remember.</p>
            <p>It is an aberrant pastime. Becoming a good writer is an uncommon aspiration and an even rarer accomplishment. The dedication I give to my own words is, for a young man in a material world, a dubious venture at best. But it’s not my career that’s on my mind right now, and it’s not the caprices of such a tired time for culture which lights the fire between my thoughts. It’s just this right here: the action, the inaction, the dream of writing. For me, the blank page is at once a search for, and the artificer of, clarity. Words have become as inseparable and indispensable to my life as breath and water. To live is to understand, and to write is to seek understanding. I can’t imagine one without the other. Writing is, by the author’s assumption, a venture towards truth. In that light, it seems only normal that “writer” should always be a bit of a byword. It’s what we already accept as the way of the world. As was quoted by Michael Lewis, “Truth is like poetry. And people hate poetry.”</p>
            <p>People who like poetry, however, will see the occupational hazards of writing—such as being wrong, or being right, or, worst of all, learning to know the difference—as necessary evil. Writing is a refiner’s fire for the mind and an automatic ontological purifier. What I love, however, is the power I have found behind my own words. The fire that burns inside these whispering pages is like a force of nature, brilliant and unstoppable. Only an artist can understand how, once mastered, words become your most prized discovery, your most treasured possession. My pen is the bridle of a pet dragon, a map of an uncharted world. I will spend hours here turning each idea over and over in my mind, feeling out the rough edges, trying to see which of all the strange puzzles I’ve found that it might belong with.</p>
            <p>It’s a vastly complex endeavor: part instinct, part cognition, part copy, and part creation. I think the similarities between the acts of living and writing are so obvious because they are essentially the same process; observed in the first person, they are both the creation of what we imagine individually, assembled from the pieces of what we comprehend together and then ruthlessly and persistently sold to the world with the imprecise maladresse of a toddler’s first steps. The two things I love most share the same challenge: on paper we call it communication, in life we call it self-actualization. Levinas, in 'Esquisse d’une Théorie des Émotions', said there was no difference, and I feel inclined to agree. The two hopelessly complex disciplines may drift arbitrarily close to our reach with age and experience, but, since we lack the linear discretion of quantifiable perceptions, we will never obtain them. Ergo my belief that the secret to good writing is the same as what Lao Tzu called the secret to life: simplicity in everything.</p>
            <p>This is the first thing I learned about writing, and it has held true for nearly all aspects of truth and poetry—or, as the philosopher would describe them, love and solipsism. Lately, though, I’ve come to think that this, like most appealing aphorisms, is a bit superficial. A writer’s attempt at accuracy by complexity misfires not because complexity must misrepresent nature, but because he or she defines complexity by the number of individual pieces in his or her creation and, in the act of creation, loses sight of the whole. Then we learn how quickly truth is lost in isolation. It is a habit of a rational mind to see things, which mean nothing in themselves, while it is the habit of the heart to see the spaces and relationships between things, which mean much more.</p>
            <p>The environment of the written page is the same. It’s the relationship between the words, not the words alone, which projects the most strongly onto the heart. Each syllable reaches out in a thousand directions, like neurons in a vastly complex cerebrum, until the sentence is no longer just a syllogism. It’s as if your ink were a seed, and you had only half-intentionally grown a living soul from the white cold press, an act of creation no less miraculous than raising a man from the clay of the earth. If there is a God, I feel inclined to think that writers, along with mothers and teachers, might be some of the few people who can relate to Him.</p>
            <p>It is significant to note, in the context of this thought, that there is a great similarity between the world of fiction, which we visit by choice, and the “reality” we inhabit regardless of our own volition. This is, once again, something only an artist will understand. The similarity is in one ever-present and never-answered question. People who write long enough will eventually find it hovering somewhere in the back of their minds. They feel hunted; the question is as persistent as a hound, and it emerges faithfully to worry their thoughts through the spaces of silence that were once filled comfortably by the pride of their work.</p>
            <p>This is the main reason for the unique feeling of unrequited love that follows the completion of a masterpiece. By hours, by days, by hearts-full of labor, the idea, which artists have done their best to translate into words, or paint, or stone, has become as dear to them as if it were their child. Soon, like parents who consider their role in a larger plan, they feel a vague sense of guilt at their pride. They want to know whose masterpiece they have constructed. Where is it from? The rendering is theirs beyond doubt, but the idea they expressed in their chosen medium is oddly unmarked. It feels, ninety percent of the time, as if it were just pieces of their past, a recombination of such via algorithm or accident, and still, ten percent of the time, as original as the child of a virgin, like a spark from a cold darkness, ex nihilo, ex machina.</p>
            <p>Why they should assume this, or want it one way or the other, they can’t ever say. The trouble is not in what is known or unknown alone, but in the space where what is seen and what is believed do not overlap. We are used to knowing, or assuming that we know, why we believe a thing. The discomfort and confusion of realizing that you don’t know how you know all that you think you know, and believing it nonetheless, is another occupational hazard of the kind of people who look too hard for truth. In lieu of explanation, they believe in a “paradox” of the sentient mind that is common to art as much as it is to theology, and not necessarily because they share a source in the human subconscious. The strange observation, in as much simplicity as Lao Tzu could have wanted, is this:</p>
            <p>“There is, in the mind of the author, a black box, which produces all the truth he or she will ever commit to paper.”</p>
            <p>“There is, behind the laws of the nature, a black box, which produces all the truth which has ever been committed to our earth.”</p>
            <p>“This realization is the strange trouble of being a writer. Masters of words feel at odd times that they might be a God, at others that they are a charlatan, and at most times as if they are the only one who understands how little there is in an average lifetime, which stands to be understood at all.”</p>
            <p>Good rhetoric has a way of stealing comfortable assumptions. I imagine Socrates must have been an excellent writer, understanding as well as he did how words could be the “midwife” of the mind. I can also see how he was unpopular. After all the unknowns have been exposed, one is sure of almost nothing. Only one thing ever appears clearer. It’s an idea, which James Salter expressed succinctly, a short time before he died:</p>
            <blockquote>“Life passes into pages, if it passes into anything.”</blockquote>
            <p>I don’t personally like to keep the company of writers. They tend to be horribly bitter people. So, when I first read that phrase, I recognized an idea which I had never heard coming from outside my own head. It wasn’t the approach of his final destination that prompted Salter’s discovery, although it made the statement more poetic in context. It’s something you learn every day as a rhetor or a poet: there is so much more in a story which is real than that which is not. It’s not uncommon for me to find, lost in my notes or an unfrequented corner of the library, stories which are far more real to me than life. In classical antiquity, great thinkers sometimes measured the “reality” of a thing by its inability to change or pass away. In this sense, my story is more real than I am. I am just a man. I will die and become whatever men become when they leave the earth. Measured in terms of transience on our physical canvas, my mortality means there is nothing less real than me. But an idea is immortal, and so is a story. Stories exist without time, and ideas, having never been born, can never really die.</p>
            <p>Whether the stories you love are “true” or not seems unimportant, if you believe them. A story can be true because it takes part of its substance from reality, but it is at best a crude representation of the original: two-dimensional, with little relationship to past reality after being mistold, mistaken, and misunderstood. We still believe that the past is real, but what is the past, in the present moment, other than a story? In the range of human perception the two are the same. They are both just ideas, which belong to no one and nothing in particular. They are only as real or as unreal as we believe them to be. The measure of our belief adds another dimension of reality that belongs to a story, seen only in retrospect; it seems so much less important what a story takes from reality than what a story gives back to reality. The inspiration of good stories has been changing lives for as long as there were people to tell them. If you can believe in a story enough for it to change your life, then it is real in a very tangible sense. We give a story life, in a simple way, if we let it change us; we give it a part to play through us on the world’s stage.</p>
            <p>There is much to be observed here and little to be understood or debated. After the little that can be said on the subject, the words of James Salter are perhaps the truest thing you will ever hear. What am I to you, but a story? What I know, what I’ve done, where I’ve been, is all just an idea in your mind, clumsy, but beautiful, and that is as corporeal as I will ever be in your life. The story of a man you never knew, or even of someone who never existed, is, in terms of its effect on your reality, only less real to you if you believe it less, and that is as close to my control as it is to the control of the author of your last novel.</p>
            <p>My sojourn in rhetoric has made my life unexpectedly simple. I now know the limits of my ability, and thereby I know the limits of my responsibility as a student, as a teacher, and as a man. They say you cannot write well if you do not write honestly. It is the same with my life. All I can do is speak as honestly as I write, act as passionately as I compose, and be to you a story as colorful and rich as any you’ve ever known.</p>
            <p>I want desperately to be real to you. I want my life, like my story, to be true enough for you to believe in. I want to live so honestly as to leave my color on your thoughts, to someday have lent you a little of the light that sparkles behind your eyes, and become the inspiration that makes you make your life a story worth telling. I want to be so real to you that even after I’m gone you keep my story written on your heart the way you hold on to a good dream or a hope for the future. In life or in death, to everyone but myself, it will be the same. I was once, and will again be, immaterial by every other measure.</p>
            <p>In the end, we are all just someone else’s story. All I ever was was all you believed you saw in me, and now that is who I have become. All I ever am is a tear-shaped impression I left on your heart, and you are the rough edges that kept mine from slipping away. I am the shadow in your eyes when the sun goes down every seventeenth of September, and you are the hope I weave between my words, the truth behind every small hour I’ll spend healing someone else’s broken heart. I am the strength in your step, the tilt in your chin, the peace of mind that will keep your hand steady and your smile real, after the years have run down, and you’ve traded your beauty for wisdom. You will be the melody in my voice every time I say the words “I love you.” I will be your favorite bedtime story; you will the one I can’t bear to live without believing.</p>
            <p>I still believe that a story exists in a place without time, and that is why I took such care of the words I left with you. What we were may have been only a feeling, only for a moment, and yet I feel a little more steady. I no longer worry about where my path may en or whether the clock might be catching up to me. How much can it really matter? I have left my heart in a place which time cannot erase.</p>
            <p>Maybe now you understand why I live my life so immaculately: this one story is all I have, and now I know the effort it takes to make a story real. When a painter has a vision for a great masterpiece, or a novelist for a great work of fiction, they know by experience that they can take no shortcuts. My life is my best story, my great masterpiece. It’s written not in seconds or in days but in the impressions I leave on the ones who walked with me. I will spend every breath crafting its message, its colors, just to see, somehow, some part of the perfection I believed in written in the imperfections of my passage. I might write a thousand stories, but I have only one to live in.</p>
            <p>So how could I not give it everything?</p>
        </article>
    );
}